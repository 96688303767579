.app {
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-left: 40px;
}

header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  width: 100%;
  position: fixed;
  background-color: white;
  top: 0px;
  z-index: 2;
}

.nav-links {
  display: flex;
}

main {
  height: 100%;
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 40px;
}

aside {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 40px;
  position: fixed;
  background-color: white;
  align-self: flex-end;
  justify-content: center;
}

a {
  color: black;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  padding-left: 30px;
}

.fab {
  font-size: 32px;
  padding: 10px 0px 10px 4px;
}

.logo {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  margin: 0;
}

footer {
  background-color: white;
  height: 50px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  overflow: hidden;
}

/* media queries */

@media screen and (max-width: 768px) {
  main {
    height: unset;
    padding-bottom: 0;
  }

  aside {
    flex-direction: row;
    height: 40px;
    width: 100%;
    position: unset;
    padding-bottom: 50px;
  }
  .fab {
    padding: 0px 10px;
  }
}

/* small mobile (mobile, 600px and down) */
@media screen and (max-width: 600px) {
  main {
    padding-right: 0;
  }

  aside {
    padding-bottom: 1em;
  }

  footer {
    height: 1em;
  }

  .app {
    margin-left: 1em;
    margin-right: 1em;
  }
}
