.home {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.colorBlock1 {
  background-color: rgb(248, 248, 248);
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.colorBlock2 {
  background-color: white;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-text-area {
  padding: 1em;
  max-width: 525px;
  font-family: "Roboto", sans-serif;
}

.home-title {
  font-weight: 900;
  font-size: 120px;
  text-transform: uppercase;
  word-break: break-all;
  line-height: 1;
  margin: 20px 0px 10px 0px;
}

.home-subtitle {
  font-size: 30px;
  font-weight: 500;
  margin: 10px 0px;
}

.home-cta {
  margin-top: 60px;
}

.home-cta a {
  text-transform: none;
  padding: 0px;
  margin: 0px 30px 0px 0px;
  font-weight: 500;
  font-size: 19px;
}

.home-about-me {
  padding: 2.5em;
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  color: #595959;
  font-weight: 300;
  max-width: 525px;
}

.home-about-me-emphasized {
  font-size: 22px;
  font-weight: 500;
}

/* media queries */

@media screen and (max-width: 1200px) {
  .home-title {
    font-size: 9.5vw;
  }
}

@media screen and (max-width: 768px) {
  .home-title {
    font-size: 100px;
  }
  .home {
    flex-direction: column;
    justify-content: unset;
  }
  .home-text-area {
    padding: 5em 1em;
  }
  .colorBlock1 {
    width: 100%;
  }
  .colorBlock2 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .home-title {
    font-size: 19vw;
  }
}

@media (hover: hover) {
  /* when hover is supported */
  .home-cta a:hover {
    color: #595959;
  }
}
