body, html {
    height: 100%;
    margin: 0px;
    box-sizing: border-box;
    /* prevents screen from getting smaller than 320px */
    min-width: 320px;
    
    
}

#root {
    height: 100%;
}